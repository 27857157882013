import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
	blogHeader,
	blogPostContainer,
	postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";

const BlogPage = () => {
	return (
		<LayoutService>
			<Helmet>
				<title>
					Explore Tampa Bay's Beauty: Top 3 Boating Views You Can't Miss
				</title>
				<meta
					name="description"
					content="Discover the ultimate boating experiences in Tampa Bay with breathtaking views. Explore downtown Tampa's skyline, witness Fort De Soto's natural wonders, and savor the luxury of Davis Island's homes. Plan your perfect boat trip now!"
				/>
				<meta
					name="keywords"
					content="Fort DeSoto, Views, Downtown Tampa, Davis Island"
				/>
			</Helmet>
			<main className="container">
				<div className="row">
					<div className="col-xxs-12">
						<div className={blogHeader}>
							<div className={imgBox}>
								<StaticImage
									alt="Sunset with palm trees"
									src="../../images/blog/sunset-with-palm-trees.jpg"
								/>
							</div>
						</div>
						<div className={blogPostContainer}>
							<div className="row center">
								<div className="col">
									<h1>
										Explore Tampa Bay's Beauty: Top 3 Boating Views You Can't
										Miss
									</h1>
									<p>November 12, 2022</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xxs-12">
									<div className={postContentBlock}>
										<p>
											There is nothing quite like taking a boat trip in the
											beautiful blue waters of Tampa Bay. The area is often
											known as the heart of Florida’s gulf coast and is known as
											a top vacation spot for people who come from all around
											the world. So, you’ve already taken in the amazing cuisine
											and on-shore activities that the area has to offer, and
											now you’ve decided to take yourself out onto the waves,
											but where is best to go to see what the area has to offer?
											Here are some of our top views:
										</p>
										<div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="Downtown tampa colorful view"
													src="../../images/banners/downtown-tampa-colorful-view.jpg"
												/>
											</div>
										</div>
										<h2>1. Explore Downtown Tampa’s colorful skyline.</h2>
										<p>
											<strong>
												For the best views of downtown Tampa itself, let your
												boat captain set sail up the Hillsborough River.
											</strong>{" "}
											Begin your journey at the beautifully pillared Tampa
											Convention Centre which sits on the river’s edge. From
											there, make your way northwards up the Hillsborough River,
											taking in the sights and sounds of the city on either
											side. Just before halfway, you will see the Curtis Hixon
											Park on your right-hand side which is especially pretty
											during the Christmas season. From mid-November to the
											start of January the park is filled with twinkling
											Christmas lights, ice skating, curling and Christmas shops
											– a truly wonderful sight. Finish your journey at the
											Armature Works – a riverfront warehouse for food and drink
											where you can hop off and grab some of the best food in
											the region.
										</p>
										<hr />
                                        <div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="Fort de soto beaches and gulf"
													src="../../images/banners/fort-de-soto-beaches-and-gulf.jpg"
												/>
											</div>
										</div>
										<h2>
											2. Witness Fort De Soto’s natural beauty in beaches and
											fauna.
										</h2>
										<p>
											If you are looking for a scenic and romantic location to
											beach your boat and hop onto the sand, feeling its warmth
											under your feet as you watch the vibrant sun setting on
											the horizon, look no further than Fort De Soto. Located
											South West of Tampa,{" "}
											<strong>
												Fort De Soto is a country park that is made up of five
												different islands connected to each other and has miles
												of award-winning beaches to explore.
											</strong>
											The area has a historical fort that you can explore,
											dating back to the Civil War or if you are a nature lover,
											spend some time spotting the various wildlife in the area
											– from dolphins to sea turtles to over 300 different types
											of birds.
										</p>
										<hr />
                                        <div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="davis island luxury homes"
													src="../../images/banners/davis-island-luxury-homes.jpg"
												/>
											</div>
										</div>
										<h2>
											3. Savor Davis Island’s luxurious homes and landscaping.
										</h2>
										<p>
											If you want to take a look at the finer things in life and
											imagine yourself in a life of luxury, why not direct your
											boat towards Davis Island, located in Hillsborough Bay.
											Cruise around the coast of the island, looking over the
											stunning coastline with sailboats docked safely in the
											harbor, just like the ones you see in the movies. This
											island is full of some of the most impressive mansions in
											Tampa and is a pretty exclusive zip code. Celebrities such
											as Tom Brady and Pat Maroon own properties here that need
											to be seen to be believed. <strong>Many of the buildings on the
											island are stunning pale pastel shades and date back to
											the 1920s.</strong> For the best views of the island, sail down the
											small canals that run through the middle of it and imagine
											yourself living like the rich and famous.
										</p>
										<hr />
										<h2>Select Your Party Boat Charter</h2>
										<h3>
											See what all's included with our wide variety of party
											boat charter packages.
										</h3>
									</div>
								</div>
							</div>
						</div>
						<ServicesList />
					</div>
				</div>
			</main>
		</LayoutService>
	);
};

export default BlogPage;
